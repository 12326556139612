<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(subtotalPrice)="data">
            <p class="m-0">
              {{ formatNumber(data.item.subtotalPrice) }}
            </p>
          </template>

          <template #cell(discountPrice)="data">
            <p class="m-0">
              {{ formatNumber(data.item.discountPrice) }}
            </p>
          </template>

          <template #cell(price)="data">
            <p class="m-0">
              {{ formatNumber(data.item.price) }}
            </p>
          </template>

          <template slot="bottom-row">
            <td class="last-row">
              <p class="m-0 bold text-white">Net Price ( Baht )</p>
            </td>
            <td class="last-row">
              <p class="m-0 bold text-white">
                {{ footerItem.grandQuantity }}
              </p>
            </td>
            <td class="last-row">
              <p class="m-0 bold text-white">
                {{ formatNumber(footerItem.subtotal) }}
              </p>
            </td>
            <td class="last-row">
              <p class="m-0 bold text-white">
                {{ formatNumber(footerItem.discount) }}
              </p>
            </td>
            <td class="last-row">
              <p class="m-0 bold text-white">
                {{ formatNumber(footerItem.grandTotal) }}
              </p>
            </td>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  components: { Pagination },
  props: {
    filter: {
      required: false,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    footerItem: {
      required: true,
      type: Object,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
  },
  methods: {
    goTransactionDetail(refId) {
      if (!refId) {
        return;
      }

      this.$router.push(`/transaction/details/${refId}`);
    },
    formatNumber(val) {
      return this.$services.utils.formatNumber(val);
    },
    handleDelete(item) {
      this.$emit("handleDelete", item);
    },

    colorClass(item) {
      if (item.status == "1") return "text-warning";
      else if (item.status == "2") return "text-success";
      else if (item.status == "3") return "text-danger";
      else return "text-secondary";
    },
    chkBirthday(birthday) {
      let birthDate = birthday.search("00:00:00");
      if (birthDate === -1) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
.color-red {
  color: #dc3545;
}
.color-green {
  color: #28a745;
}
.color-blue {
  color: lightcoral;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

// ! override
.last-row {
  background-color: var(--secondary-color);
}
</style>
