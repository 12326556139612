<template>
  <div>
    <div class="header-title">
      <h1 class="title">Zone</h1>
      <b-button class="btn-filter" @click="handleCreate()"> Create </b-button>
    </div>
    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template v-slot:cell(actions)="{ item }">
        <font-awesome-icon
          class="mr-4 pointer"
          icon="pen"
          @click="handleEdit(item)"
        />
        <font-awesome-icon
          v-if="previewOrPublish == 0"
          class="pointer"
          icon="trash-alt"
          @click="handleDelete(item)"
        />
      </template>
      <div class="text-center text-black my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Loading...</strong>
      </div>
    </b-table>
    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :rows="rows"
      :filter="filter"
      @pagination="pagination"
    />
    <zone-form
      :visible="visible"
      :form="form"
      @clearForm="clearForm"
      @getZone="getZone"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import ZoneForm from "./ZoneForm.vue";
export default {
  name: "ServiceBranchTableZone",
  components: { Pagination, ZoneForm },
  props: {
    previewOrPublish: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      form: {
        isActive: 1,
      },
      items: [],
      isBusy: false,
      fields: [
        {
          key: "name",
          label: "Name",
          class: "w-50px text-nowrap",
        },
        {
          key: "quantity",
          label: "Quality",
          class: "w-50px text-nowrap",
        },
        { key: "actions", label: "Action", class: "w-50px text-nowrap" },
      ],
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: { skip: 1, take: 10, page: 1 },
      rows: 0,

      visible: false,
    };
  },
  mounted() {
    this.getZone();
  },
  methods: {
    async getZone() {
      this.isBusy = true;
      const getData = await this.$services.event.getZone(
        this.$route.params.id,
        this.filter.skip,
        this.filter.take
      );
      if (getData.result == 1) {
        const { data, count } = getData.detail;
        this.items = data;
        this.rows = count;
      }

      this.isBusy = false;
    },
    async handleDelete(val) {
      // console.log(val);
      const { id } = val;
      const remove = await this.$services.event.removeEventZone(
        this.$route.params.id,
        id
      );
      if (remove.result == 1) {
        this.getZone();
      }
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getZone();
    },
    pagination(page) {
      this.filter.page = page;
      this.filter.skip = page;
      this.getZone();
    },
    handleCreate() {
      this.visible = true;
    },
    handleEdit(val) {
      this.form = val;
      this.visible = true;
    },
    clearForm() {
      this.form = {};
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-title {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}
.title {
  font-size: 18px;
}
</style>
