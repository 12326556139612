<template>
  <div>
    <SideBarFilter
      :filter="filter"
      ref="sidebarServiceBranchBanner"
      placeholder="Banner"
      @searchAll="haddleSave"
      @clearForm="clearForm"
      title="Banner info"
      :hideStatusFilter="false"
      :hideSearchBar="true"
      :isLoading="loading"
      handleSideBar
      hideFilter
      :textSubmit="form?.id ? 'Update' : 'Create'"
    >
      <template v-slot:filter-option>
        <div class="my-3">
          <UploadFile
            textFloat="Image"
            placeholder="Please Choose File"
            format="image"
            name="file"
            text="* ขนาดรูปภาพที่แนะนำ 800 * 450"
            accept="image/*"
            id="uploadfile"
            required="Please select image."
            isRequired
            @onFileChange="onFileChange($event)"
            :fileName="form.imgUrl"
            v-model="form.imgUrl"
            :v="$v.form.imgUrl"
            :isValidate="$v.form.imgUrl.$error"
          />
        </div>
        <div
          class="render-image"
          :style="`background-image:url(${imageDisplay})`"
        ></div>
        <div class="my-3">
          <InputText
            v-model="form.sortOrder"
            class="input"
            textFloat="Sort Order"
            placeholder="0"
            type="number"
            name="number"
            isRequired
            :isValidate="$v.form.sortOrder.$error"
            :v="$v.form.sortOrder"
          />
        </div>
        <div class="my-3">
          <b-form-checkbox
            v-model="form.isDeleted"
            switch
            :value="0 || false"
            :unchecked-value="1 || true"
          >
            Active
          </b-form-checkbox>
        </div></template
      ></SideBarFilter
    >
  </div>
</template>

<script>
import UploadFile from "@/components/inputs/UploadFile";
import { required, requiredIf, minValue } from "vuelidate/lib/validators";
export default {
  name: "ServiceBranchBannerForm",
  components: { UploadFile },
  props: {
    visible: {
      type: Boolean,
      required: false,
    },
    data: {
      type: Object,
      required: false,
    },
  },
  validations() {
    return {
      form: {
        imgUrl: { required },
        // base64: {
        //   required,
        // },
        // mobileImgUpload: {
        //   base64: { required },
        // },
        // startTimeDisplay: { required },
        // endTimeDisplay: { required },
        sortOrder: { required, minValue: minValue(1) },
        // bannerTypeId: { required },
        // url: {
        //   required: requiredIf(() => {
        //     return this.form.bannerTypeId == 6;
        //   }),
        // },
      },
    };
  },
  data() {
    return { form: {}, filter: {}, imageDisplay: "", loading: false };
  },
  watch: {
    visible(val) {
      if (val === true) {
        this.form = this.data;
        this.imageDisplay = this.data.imgShowUrl;
        this.$refs.sidebarServiceBranchBanner.show();
      } else {
        this.$refs.sidebarServiceBranchBanner.hide();
      }
    },
  },
  methods: {
    async haddleSave() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      this.loading = true;
      let respone = null;
      const payload = {
        ...this.form,
        isDeleted: +this.form.isDeleted,
        isVideo: +this.form.isDeleted,
      };

      if (this.form.id) {
        respone = await this.$services.event.updateEventGalleryById(
          this.form.eventId,
          this.form.id,
          payload
        );
        this.openModalAlertText(respone.detail);
      } else {
        respone = await this.$services.event.insertEventGallery(
          this.$route.params.id,
          payload
        );
        this.openModalAlertText(respone.detail);
      }
      this.$emit("fetchTable");
      this.clearForm();
      this.loading = false;
    },
    clearForm() {
      this.$v.$reset();
      this.form = {
        sortOrder: 0,
        isDeleted: 0,
        isVideo: 0,
        base64: {
          base64: null,
        },
        imgUrl: "",
      };
      this.imageDisplay = "";
      this.$emit("clearForm");
    },
    onFileChange(file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (result) => {
        this.form.imgUrl = file.name;
        const base64 = {
          base64: result.target.result,
        };

        this.form = { ...this.form, base64 };

        this.imageDisplay = result.target.result;
      };
    },
    openModalAlertText(val) {
      this.$emit("openModalAlertText", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.render-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 200px;
  border: 1px dashed gray;
}
.border-red {
  border-color: red;
}
</style>
