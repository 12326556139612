<template>
  <div class="transaction-detail">
    <div v-if="isBusy">
      <div class="text-center text-black my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Loading...</strong>
      </div>
    </div>
    <div v-else>
      <b-row>
        <b-col cols="12" md="9">
          <p class="m-0 text-header bold">
            <b-icon
              class="pointer"
              icon="arrow-left-circle"
              @click="goCustomerDetail"
            ></b-icon>
            TRANSACTION DETAIL REF : {{ refId }}
          </p>
        </b-col>
        <b-col cols="12" md="3">
          <div class="header right" v-if="data">
            <div class="status" :style="`background-color:${data.color}`">
              <p class="m-0">
                {{ data.statusName }}
              </p>
            </div>
          </div>
        </b-col>
      </b-row>

      <div class="detail">
        <div class="detail-header">
          <p class="m-0 bold text-white">Transaction Detail</p>
          <!-- <p class="m-0 bold">Date time transaction :</p> -->
        </div>
        <div class="detail-body" v-if="data">
          <b-row>
            <b-col cols="12" md="6">
              <div class="list-detail">
                <p class="m-0 bold mw-100">Full name</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 w-full">
                  {{ data.fullName }}
                </p>
              </div>
            </b-col>

            <b-col cols="12" md="6">
              <div class="list-detail">
                <p class="m-0 bold mw-100">Event Name</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 w-full">
                  {{ data.eventName }}
                </p>
              </div>
            </b-col>

            <b-col cols="12" md="6">
              <div class="list-detail">
                <p class="m-0 bold mw-100">Email</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 w-full">
                  {{ data.email || "-" }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <div class="list-detail">
                <p class="m-0 bold mw-100">Round</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 w-full">
                  {{
                    $moment(data.bookingDate)
                      .locale("th")
                      .format("Do MMMM YYYY")
                  }}
                  {{ data.startTimeShow }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <div class="list-detail">
                <p class="m-0 bold mw-100">Telephone</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 w-full">
                  {{ data.telephone || "-" }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <div class="list-detail">
                <p class="m-0 bold mw-100">Net Pirce</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 w-full">
                  {{ formatNumber(data.grandTotal) }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <div class="list-detail">
                <p class="m-0 bold mw-100">Invoice No</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 w-full">
                  {{ data.invoiceNo || "-" }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <div class="list-detail">
                <p class="m-0 bold mw-100">Transaction Id</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 w-full">
                  {{ data.transactionShowId }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <div class="list-detail">
                <p class="m-0 bold mw-100">Note</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 w-full">
                  {{ data.note || "-" }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="6"> </b-col>
            <b-col cols="12" md="6">
              <div class="list-detail">
                <p class="m-0 bold mw-100">Promocode</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 w-full">
                  {{ data.promocodeSerialNo || "-" }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <div class="list-detail">
                <p class="m-0 bold mw-100">Serial</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 bold w-full">
                  {{ data.promoCodeName || "-" }}
                </p>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>

      <div class="detail">
        <div class="detail-header">
          <p class="m-0 bold text-white">Payment Info</p>
          <b-button class="btn-filter" @click="getPaymentInfo">
            Payment
          </b-button>
        </div>
        <div class="detail-body" v-if="data">
          <b-row>
            <b-col cols="12" md="6">
              <div class="list-detail">
                <p class="m-0 bold mw-100">Payment Type</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 w-full">
                  {{ data.paymentTypeName }}
                </p>
              </div>

              <div class="list-detail">
                <p class="m-0 bold mw-100">Payment Ref</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 w-full">
                  {{ data.paymentTransactionId || "-" }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <div class="list-detail">
                <p class="m-0 bold mw-100">Payment Time</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 w-full">
                  {{ data.paymentTime || "-" }}
                </p>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>

      <div class="detail" v-if="data && data.hasBilling == 1">
        <div class="detail-header">
          <p class="m-0 bold text-white">Billing</p>
          <!-- <b-button class="btn-filter">click me</b-button> -->
        </div>
        <div class="detail-body">
          <b-row>
            <b-col cols="12" md="6">
              <div class="list-detail">
                <p class="m-0 bold mw-100">Tax Id</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 w-full">
                  {{ data.billingDetail.taxId }}
                </p>
              </div>

              <div class="list-detail">
                <p class="m-0 bold mw-100">Address</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 w-full">
                  {{ data.billingDetail.address }}
                </p>
              </div>

              <div class="list-detail">
                <p class="m-0 bold mw-100">District</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 w-full">
                  {{ data.billingDetail.districtName }}
                </p>
              </div>

              <div class="list-detail">
                <p class="m-0 bold mw-100">Zipcode</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 w-full">
                  {{ data.billingDetail.zipCode }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <div class="list-detail">
                <p class="m-0 bold mw-100">Name</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 w-full">
                  {{ data.billingDetail.name }}
                </p>
              </div>

              <div class="list-detail">
                <p class="m-0 bold mw-100">Sub District</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 w-full">
                  {{ data.billingDetail.subdistrictName }}
                </p>
              </div>

              <div class="list-detail">
                <p class="m-0 bold mw-100">Province</p>
                <p class="m-0 mr-2">:</p>
                <p class="m-0 w-full">
                  {{ data.billingDetail.provinceName }}
                </p>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>

      <div class="table" v-if="items.length > 0">
        <Table
          :items="items"
          :footerItem="footerItem"
          :fields="fields"
          :isBusy="isBusy"
          :rows="rows"
          :pageOptions="pageOptions"
        />
      </div>
    </div>
    <ModalConfirmPayment
      :dataPayment="dataPayment"
      @confirmSuccess="confirmSuccess"
    />
  </div>
</template>

<script>
import ModalConfirmPayment from "@/components/modal/ModalConfirmPayment.vue";
import Table from "./table";
export default {
  name: "ServiceBranchCustomerDetail",
  components: {
    ModalConfirmPayment,
    Table,
  },
  props: {
    refId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: null,
      dataPayment: null,

      // state table
      items: [],
      footerItem: {},

      fields: [
        {
          key: "productName",
          label: "Product Name",
        },
        {
          key: "quantity",
          label: "Quantity",
        },
        {
          key: "subtotalPrice",
          label: "Subtotal Price",
        },
        {
          key: "discountPrice",
          label: "Discount",
        },
        {
          key: "price",
          label: "Price",
        },
      ],
      isBusy: false,
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
    };
  },
  mounted() {
    this.getDetailTransaction();
    // this.getPaymentInfo();
  },
  methods: {
    async getDetailTransaction() {
      this.isBusy = true;
      const getData = await this.$services.transaction.getTransactionDetail(
        this.refId
      );

      if (getData.result == 1) {
        this.data = getData.detail;
        this.items = getData.detail.productTable;
        this.footerItem = getData.detail.summaryProduct;
      }
      this.isBusy = false;
    },
    async getPaymentInfo() {
      const getData = await this.$services.transaction.getPaymentInfo(
        this.refId
      );

      if (getData.result == 1) {
        // add state base64
        getData.detail.bankTransferList = getData.detail.bankTransferList.map(
          (x) => {
            // change format dateonly
            x.transactionDetail.dateOnly = x.transactionDetail.dateOnly
              .split("-")
              .reverse()
              .join("-");

            // add new key to obj
            let obj = {
              ...x,
              base64: {
                base64: null,
              },
              imageName: x.transactionDetail.slipImgUrl.split("/").reverse()[0],
            };
            // conver time

            x.transactionDetail.timeOnly = this.convertTime(
              x.transactionDetail.timeOnly
            );

            return obj;
          }
        );

        this.dataPayment = getData.detail;

        this.$bvModal.show("modal-confirm-payment");
      }
    },
    convertTime(time) {
      let getTime = `${this.$moment().format("YYYY-MM-DDT")}${time}`;

      return getTime;
    },

    convertTimeHHMM(time) {
      return this.$moment(time).format("HH:mm");
    },
    confirmSuccess() {
      this.getDetailTransaction();
    },
    formatNumber(val) {
      return this.$services.utils.formatNumber(val);
    },
    goCustomerDetail() {
      this.$emit("goCustomerDetail");
    },
  },
};
</script>

<style lang="scss" scoped>
.transaction-detail {
  // font

  .text-header {
    font-size: 18px;
  }

  .bold {
    font-weight: bold;
  }

  .header {
    &.right {
      display: flex;
      justify-content: flex-end;
    }

    .status {
      padding: 5px 15px;
      border-radius: 15px;
      color: white;
    }
  }

  .detail {
    .detail-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--secondary-color);
      padding: 10px;
      margin-top: 10px;
    }

    .detail-body {
      background: white;
      padding: 10px;

      .list-detail {
        display: flex;
        margin-bottom: 5px;

        .mw-100 {
          min-width: 100px;
        }

        .w-full {
          width: 100%;
          text-align: right;
        }
      }
    }
  }

  .table {
    margin-top: 10px;
  }
}

// ! override
.btn-filter {
  min-width: 100px;
}
</style>
