<template>
  <div>
    <SideBarFilter
      ref="sidebarServiceBranchTicket"
      title="Ticket"
      :filter="filter"
      :hideStatusFilter="false"
      :hideSearchBar="true"
      :textSubmit="form?.ticketId ? 'Update' : 'Create'"
      @searchAll="handleSave"
      @clearForm="clearForm"
      handleSideBar
      hideFilter
      :isLoading="isLoading"
      @clearLoading="isLoading = false"
    >
      <template v-slot:filter-option>
        <div class="my-3">
          <div class="list-lang">
            <div
              v-for="(item, index) in listLang"
              :key="item.languageId"
              class="box-lang"
              :class="selectedLang == item.languageId && 'active'"
              @click="selectedLang = item.languageId"
            >
              {{ item.nation }}
            </div>
          </div>
        </div>
        <div class="my-3">
          <InputText
            v-model="form.product[indexLanguage].name"
            class="input"
            :textFloat="`Ticket Name`"
            :placeholder="`Ticket Name`"
            type="text"
            name="text"
            isRequired
            :selectLang="language"
            :isValidate="$v.form.product.$each[indexLanguage].$error"
            :v="$v.form.product.$each[indexLanguage].name"
          />
        </div>
        <div class="my-3">
          <InputSelect
            title="Zone Name"
            name="type"
            isRequired
            class="mt-2"
            v-model="form.eventZoneId"
            valueField="id"
            textField="name"
            :options="optionTicketZone"
            @onDataChange="selectZoneId($event)"
            :disabled="previewOrPublish == 1 && 'ticketId' in form"
            :isValidate="$v.form.eventZoneId.$error"
            :v="$v.form.eventZoneId"
          >
            <template v-slot:option-first>
              <b-form-select-option :value="null" disabled>
                -- Select Zone --
              </b-form-select-option>
            </template>
          </InputSelect>
        </div>

        <div class="my-3" v-if="!$isEventGroup">
          <InputSelect
            title="Ticket Class"
            name="type"
            isRequired
            class="mt-2"
            v-model="form.ticketClassId"
            valueField="id"
            textField="name"
            :options="optionTicketClass"
            @onDataChange="selectTicket($event)"
            :isValidate="$v.form.ticketClassId.$error"
            :v="$v.form.ticketClassId"
          >
            <template v-slot:option-first>
              <b-form-select-option :value="null" disabled>
                -- Select Ticket --
              </b-form-select-option>
            </template>
          </InputSelect>
        </div>
        <div class="my-3">
          <InputText
            v-model="form.quantity"
            class="input"
            textFloat="Quantity"
            placeholder="0"
            type="number"
            name="number"
            isRequired
            :isValidate="$v.form.quantity.$error"
            :v="$v.form.quantity"
          />
        </div>
        <div class="my-3">
          <InputText
            disabled
            v-model="form.price"
            class="input"
            textFloat="Price"
            placeholder="0"
            type="number"
            name="number"
            isRequired
            :isValidate="$v.form.price.$error"
            :v="$v.form.price"
          />
        </div>
      </template>
    </SideBarFilter>
    <ModalAlertText :textModal="textModal" :arrModal="arrStatus" />
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import ModalAlertText from "@/components/modal/ModalAlertText.vue";
export default {
  name: "ServiceBranchTicketForm",
  components: { ModalAlertText },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    form: { type: Object, required: true },
    previewOrPublish: { type: Number, required: true },
  },
  data() {
    return {
      isLoading: false,
      filter: {},

      selectedLang: 2,
      listLang: [],

      optionTicketZone: [],
      optionTicketClass: [],

      textModal: "",
      arrStatus: [],
    };
  },
  validations() {
    return {
      form: {
        price: { required },
        quantity: { required },
        ticketClassId: { required },
        eventZoneId: { required },
        product: {
          $each: {
            name: {
              required: requiredIf(() => {
                return (
                  this.form.product[0].name == "" && this.indexLanguage === 0
                );
              }),
            },
          },
        },
      },
    };
  },
  watch: {
    visible(val) {
      if (val === true) {
        this.getLanguageOption();
        this.getOptionEventZone();
        this.getOptionTicketClass();
        this.$refs.sidebarServiceBranchTicket.show();
      } else {
        this.$refs.sidebarServiceBranchTicket.hide();
        this.clearForm();
      }
    },
  },
  computed: {
    language() {
      return this.listLang.find((x) => x.languageId === this.selectedLang);
    },
    indexLanguage() {
      return this.form?.product.findIndex(
        (x) => x.languageId === this.selectedLang
      );
    },
  },
  mounted() {},
  methods: {
    async handleSave() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isLoading = true;

      let respone = null;

      if (this.form.ticketId) {
        respone = await this.$services.event.updateEventTicket(
          this.$route.params.id,
          this.form.ticketId,
          this.form
        );
      } else {
        respone = await this.$services.event.insertEventTicket(
          this.$route.params.id,
          this.form
        );
      }
      this.openModalAlertText(respone.detail);
      this.isLoading = false;
      this.$emit("clearForm");
      this.$emit("getTicket");
    },
    async getLanguageOption() {
      const getData = await this.$services.master.getLanguageOption();

      if (getData.result == 1) {
        this.listLang = getData.detail;
        this.selectedLang = this.listLang[0].languageId;
      }
    },
    async getOptionEventZone() {
      const getData = await this.$services.master.getOptionEventZone(
        this.$route.params.id
      );

      if (getData.result == 1) {
        this.optionTicketZone = getData.detail;
      }
    },
    async getOptionTicketClass() {
      const getData = await this.$services.master.getOptionTicketClass();

      if (getData.result == 1) {
        this.optionTicketClass = getData.detail;
      }
    },
    selectZoneId(val) {
      this.form.eventZoneId = val;
    },
    selectTicket(val) {
      this.form.ticketClassId = val;
    },
    clearForm() {
      this.listLang = [];

      this.optionTicketZone = [];
      this.optionTicketClass = [];
      this.$v.$reset();
      this.$emit("clearForm");
    },
    openModalAlertText(text) {
      this.textModal = text;
      this.$emit("openModalAlertText", text);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-lang {
  display: flex;
  justify-content: right;

  .box-lang {
    padding: 5px 10px;
    margin-right: 5px;
    border: 1px solid black;
    cursor: pointer;

    &.active {
      background: var(--secondary-color);
      color: white;
    }
  }
}
</style>
