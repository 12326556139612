<template>
  <div>
    <div class="header-title">
      <h1 class="title">Ticket</h1>
      <div v-if="!isLockProduct">
        <b-button class="btn-filter" @click="handleCreate"> Create </b-button>
      </div>
    </div>
    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template #cell(price)="{ item }">
        <p class="m-0">
          {{ formatNumber(item.price) }}
        </p>
      </template>

      <template v-slot:cell(actions)="{ item }">
        <font-awesome-icon
          class="mr-4 pointer"
          icon="pen"
          @click="handleEdit(item)"
        />
        <font-awesome-icon
          v-if="previewOrPublish == 0 && !isLockProduct"
          class="pointer"
          icon="trash-alt"
          @click="handleDelete(item)"
        />
      </template>

      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>
    <div v-if="!isLockProduct">
      <Pagination
        @handleChangeTake="handleChangeTake"
        :pageOptions="pageOptions"
        :rows="rows"
        :filter="filter"
        @pagination="pagination"
      />
    </div>
    <ticket-form
      :visible="visible"
      :form="form"
      :previewOrPublish="previewOrPublish"
      @clearForm="clearForm"
      @getTicket="getTicket"
      @openModalAlertText="openModalAlertText"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import TicketForm from "./TicketForm.vue";
export default {
  name: "ServiceBranchTableTicket",
  components: { Pagination, TicketForm },
  props: {
    previewOrPublish: {
      required: true,
      type: Number,
    },
    isLockProduct: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      isBusy: false,
      fields: [
        {
          key: "productName",
          label: "Name",
          class: "w-50px text-nowrap",
        },
        {
          key: "quantity",
          label: "Quality",
          class: "w-50px text-nowrap",
        },
        { key: "actions", label: "Action", class: "w-50px text-nowrap" },
      ],
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: { skip: 1, take: 10, page: 1 },
      rows: 0,

      visible: false,
      form: {
        ticketTypeId: 1,
        productTypeId: 1,
        ticketClassId: null,
        eventZoneId: null,
        quantity: null,
        price: null,
        product: [
          {
            languageId: 2,
            name: "",
            nation: "",
          },
          {
            languageId: 1,
            name: "",
            nation: "",
          },
        ],
      },
    };
  },
  mounted() {
    this.getTicket();
  },
  methods: {
    async getTicket() {
      this.isBusy = true;
      const getData = await this.$services.event.getTicket(
        this.$route.params.id,
        this.filter.skip,
        this.filter.take
      );

      if (getData.result == 1) {
        const { data, count } = getData.detail;
        this.items = data;
        this.rows = count;
      }
      this.isBusy = false;
    },
    async handleEdit(val) {
      let { ticketId } = val;
      const getData = await this.$services.event.getEventTicketById(
        this.$route.params.id,
        ticketId
      );
      const { detail } = getData;
      this.form = detail;
      this.visible = true;
    },
    async handleDelete(val) {
      // console.log(val);
      const { ticketId } = val;
      const removeticket = await this.$services.event.removeEventTicketId(
        this.$route.params.id,
        ticketId
      );
      if (removeticket.result == 1) {
        this.getTicket();
      }
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getTicket();
    },
    pagination(page) {
      this.filter.page = page;
      this.getTicket();
    },
    handleCreate() {
      this.visible = true;
    },
    formatNumber(val) {
      return this.$services.utils.formatNumber(val);
    },
    clearForm() {
      this.visible = false;
      this.form = {
        ticketTypeId: 1,
        productTypeId: 1,
        ticketClassId: null,
        eventZoneId: null,
        quantity: null,
        price: null,
        product: [
          {
            languageId: 2,
            name: "",
            nation: "",
          },
          {
            languageId: 1,
            name: "",
            nation: "",
          },
        ],
      };
    },
    openModalAlertText(val, arr) {
      this.$emit("openModalAlertText", val, arr);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-title {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}
.title {
  font-size: 18px;
}
</style>
