<template>
  <div class="container">
    <b-container class="bv-example-row bv-example-row-flex-cols">
      <b-row v-if="isLockProduct === false">
        <b-col> <table-zone :previewOrPublish="previewOrPublish" /> </b-col
      ></b-row>
      <div class="tw-mt-8" v-if="isLockProduct === false"><hr /></div>

      <b-row>
        <b-col>
          <table-ticket
            :previewOrPublish="previewOrPublish"
            :isLockProduct="isLockProduct"
            @openModalAlertText="openModalAlertText" /></b-col
      ></b-row>
    </b-container>
  </div>
</template>

<script>
import TableZone from "./component/TableZone.vue";
import TableTicket from "./component/TableTicket.vue";
export default {
  name: "ServiceBranchTicketAndZoneTab",
  components: { TableZone, TableTicket },
  props: {
    previewOrPublish: {
      type: Number,
      required: true,
      default: 0,
    },
    isLockProduct: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openModalAlertText(val, arr) {
      this.$emit("openModalAlertText", val, arr);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: white;
  padding: 15px;
  min-height: 60vh;
}
</style>
