<template>
  <div class="tap6" v-if="!loading">
    <div class="tw-flex tw-flex-row tw-flex-wrap tw-mb-6 tw-h-8">
      <div class="tw-text-left tw-text-xl tw-w-full tw-font-bold md:tw-w-1/4">
        Additional Data
      </div>
      <div class="tw-text-left tw-w-full md:tw-w-3/4 md:tw-text-right">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="radio-slots-additional"
            v-model="data.event.useEventInfoTemplate"
            :options="options"
            :aria-describedby="ariaDescribedby"
            name="radio-slots-additional"
          >
          </b-form-radio-group>
        </b-form-group>
      </div>
    </div>
    <div class="sec1">
      <b-row>
        <b-col cols="1">
          <h4 class="mb-2">Id</h4>
        </b-col>
        <b-col cols="5">
          <h4 class="mb-2">Key</h4>
        </b-col>
        <b-col cols="6">
          <div class="render-language">
            <h4 class="mb-2">Value</h4>
            <span v-if="selectLang?.name" class="m-0 text-right">
              ({{ selectLang.name }})
            </span>
          </div>
        </b-col>
      </b-row>

      <div v-if="newData.length > 0">
        <b-row v-for="(item, index) in newData" :key="index">
          <b-col cols="1">
            <p class="m-0">
              {{ handleId(item.eventInfoId) }}
            </p>
          </b-col>
          <b-col cols="5">
            <InputText
              v-model="filterLang(item.additionalLanguageData).topic"
              placeholder="Value"
              textFloat=""
              type="text"
              name="text"
              :isRequired="selectLang.languageId === 2"
              :isValidate="
                v.form.additionalData.$each[indexLanguage]
                  .additionalLanguageData.$each[0].topic.$error
              "
              :v="
                v.form.additionalData.$each[indexLanguage]
                  .additionalLanguageData.$each[0].topic
              "
            />
          </b-col>
          <b-col cols="6">
            <div class="input-value">
              <InputText
                v-model="filterLang(item.additionalLanguageData).detail"
                class="input"
                placeholder="Value"
                textFloat=""
                type="text"
                name="text"
                :isRequired="selectLang.languageId === 2"
                :isValidate="
                  v.form.additionalData.$each[indexLanguage]
                    .additionalLanguageData.$each[0].detail.$error
                "
                :v="
                  v.form.additionalData.$each[indexLanguage]
                    .additionalLanguageData.$each[0].detail
                "
              />

              <font-awesome-icon
                v-if="!data.event.useEventInfoTemplate"
                icon="trash-alt"
                class="ml-2 pointer icon-delete"
                @click="removeItem(item)"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="no-data" v-else>
        <h3>No additional data</h3>
      </div>
      <div class="add-data" v-if="data.event.useEventInfoTemplate === false">
        <b-icon @click="addData" icon="plus-square-fill"> </b-icon>
        <p @click="addData" class="m-0 ml-2 underline">Add Data</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceBranchAdditionalTab",
  props: {
    selectLang: {
      type: Object,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    v: {
      type: Object,
      require: false,
    },
    indexLanguage: {
      type: Number,
      require: false,
      default: 0,
    },
  },
  data() {
    return {
      options: [
        { text: "Use Template", value: true },
        { text: "Custom", value: false },
      ],
    };
  },

  computed: {
    newData() {
      if (this.data.additionalData !== undefined) {
        let arr = this.data.additionalData.filter((x) => x.isDisplay != 0);
        return arr;
      } else {
        return [];
      }
    },
  },
  methods: {
    handleId(id) {
      let newId = "";

      const typeId = typeof id;

      if (typeId == "string") {
        newId = "N";
      } else {
        newId = id;
      }

      return newId;
    },

    removeItem(obj) {
      const index = this.data.additionalData
        .map((e) => e.eventInfoId)
        .indexOf(obj.eventInfoId);
      this.data.additionalData[index].isDisplay = 0;
    },
    addData() {
      const index = this.data.additionalData.length + 1;

      this.data.additionalData.push({
        eventInfoId: `new${index}`,
        sortOrder: index,
        isDisplay: 1,
        additionalLanguageData: [
          {
            id: 1,
            languageId: 2,
            topic: "",
            detail: "",
          },
          {
            id: 2,
            languageId: 1,
            topic: "",
            detail: "",
          },
        ],
      });
    },
    filterLang(arr) {
      const final = this.$services.utils.fillterLang(
        arr,
        this.selectLang.languageId
      );

      return final;
    },
  },
};
</script>

<style lang="scss" scoped>
.tap6 {
  background: white;
  padding: 15px;
  min-height: 60vh;

  .title {
    font-size: 18px;
  }

  .div-divide {
    border-bottom: 1px solid darkgray;
    padding: 10px 0px;
  }

  .header-title {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
  }

  .input-value {
    display: flex;
    align-items: center;

    .input {
      width: 100%;
    }
  }

  .add-data {
    display: flex;
    align-items: center;
    width: max-content;
    cursor: pointer;
    color: var(--secondary-color);

    .underline {
      text-decoration: underline;
    }
  }

  .icon-delete {
    margin-bottom: 15px;
    color: var(--secondary-color);
  }

  .render-language {
    display: flex;
    justify-content: space-between;

    .text-right {
      float: right;
      font-weight: 100;
      font-size: 14px;
    }
  }

  // nodata
  .no-data {
    text-align: center;
    padding: 15px 0px;
    opacity: 0.4;
  }
}

// ! Override
.btn-filter {
  width: 100%;
  max-width: 120px;
}
</style>
