<template>
  <div class="tw-p-5">
    <div class="tw-mb-2">
      <div class="tw-flex tw-flex-row tw-flex-wrap">
        <div class="tw-text-left tw-text-xl tw-w-1/4 tw-font-bold">Banner</div>
        <div class="tw-text-right tw-w-3/4">
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="radio-slots"
              v-model="data.event.useBannerTemplate"
              :options="options"
              :aria-describedby="ariaDescribedby"
              name="radio-options-slots"
            >
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <div class="tw-flex tw-w-full tw-justify-end">
        <b-button
          class=""
          @click="handleClickCreate"
          v-if="data.event.useBannerTemplate !== true"
          >Create</b-button
        >
      </div>
    </div>
    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template #cell(imgShowUrl)="{ item }">
        <div
          class="render-image"
          :style="`background-image:url(${item.imgShowUrl})`"
        ></div>
      </template>

      <template #cell(isDeleted)="{ item }">
        <p
          class="m-0"
          :class="item.isDeleted == false ? 'color-green' : 'color-red'"
        >
          {{ item.isDeleted == false ? "Active" : "Inactive" }}
        </p>
      </template>

      <template v-slot:cell(actions)="{ item }">
        <font-awesome-icon
          class="mr-4 pointer"
          icon="pen"
          @click="editBanner(item)"
        />
        <font-awesome-icon
          class="pointer"
          icon="trash-alt"
          @click="removeBanner(item)"
        />
      </template>
    </b-table>
    <banner-form
      @clearForm="clearForm"
      :data="form"
      :visible="visible"
      @fetchTable="fetchTable"
      @openModalAlertText="openModalAlertText"
    />
    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :rows="rows"
      :filter="filter"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import BannerForm from "./component/bannerForm.vue";
export default {
  name: "ServiceBranchBannerTab",
  components: { Pagination, BannerForm },
  props: {
    data: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      options: [
        { text: "Use Template", value: true },
        { text: "Custom", value: false },
      ],
      filter: {
        skip: 1,
        take: 5,
        page: 1,
      },
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 15, text: "15 / page" },
        { value: 20, text: "20 / page" },
      ],
      rows: 0,
      isBusy: false,

      items: [],

      visible: false,
      form: {},
    };
  },
  computed: {
    fields() {
      let displayFields = [
        {
          key: "imgShowUrl",
          label: "Image Destop",
          class: "w-100px text-nowrap",
        },

        {
          key: "sortOrder",
          label: "Sort Order",
          class: "w-50px text-nowrap",
        },
        {
          key: "isDeleted",
          label: "Status",
          class: "w-50px text-nowrap",
        },
        { key: "actions", label: "Action", class: "w-50px text-nowrap" },
      ];
      if (this.data.event.useBannerTemplate === true) {
        return displayFields.splice(0, displayFields.length - 1);
      } else {
        return displayFields;
      }
    },
  },
  watch: {
    "data.event.useBannerTemplate"(val) {
      this.fetchTable();
    },
  },
  mounted() {
    this.fetchTable();
  },
  methods: {
    async getEventGallery() {
      const getData = await this.$services.event.getGallery(
        this.$route.params.id,
        this.filter.skip,
        this.filter.take
      );

      if (getData.result == 1) {
        this.items = getData.detail.data;
        this.rows = getData.detail.count;
      }
    },
    async getBannerTemplateList() {
      const { page, take } = this.filter;
      const id = this.$route.params.eventGroupId;
      const respone = await this.$services.eventgroup.getBannerTemplate(
        id,
        page,
        take
      );
      if (respone.result === 1) {
        const { count, data } = respone.detail;
        this.rows = count;
        this.items = data;
      }
    },
    async removeBanner(val) {
      let { eventId, id } = val;

      const respone = await this.$services.event.removeEventGalleryById(
        eventId,
        id
      );

      this.openModalAlertText(respone.detail);
      this.fetchTable();
    },
    fetchTable() {
      if (this.data.event.useBannerTemplate === true) {
        this.getBannerTemplateList();
      } else {
        this.getEventGallery();
      }
    },
    editBanner(val) {
      this.form = val;
      this.visible = true;
    },
    clearForm() {
      this.form = {
        sortOrder: 0,
        isDeleted: 0,
        isVideo: 0,
        base64: {
          base64: null,
        },
        imgUrl: "",
      };
      this.visible = false;
    },

    handleClickCreate() {
      this.visible = true;
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.skip = 1;
      this.filter.take = value;
      this.fetchTable();
    },
    pagination(val) {
      this.filter.skip = val;
      this.filter.page = val;
      this.fetchTable();
    },
    openModalAlertText(text) {
      this.$emit("openModalAlertText", text);
    },
  },
};
</script>

<style lang="scss" scoped>
.render-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 250px;
  height: 180px;
  margin: auto;
}
.color-red {
  color: #dc3545;
}

.color-green {
  color: #28a745;
}
</style>
