<template>
  <div>
    <SideBarFilter
      ref="sidebarServiceBranchZone"
      title="Zone"
      :filter="filter"
      :hideStatusFilter="false"
      :hideSearchBar="true"
      :textSubmit="form?.id ? 'Update' : 'Create'"
      @searchAll="handleSave"
      @clearForm="clearForm"
      handleSideBar
      hideFilter
      :isLoading="isLoading"
      @clearLoading="isLoading = false"
    >
      <template v-slot:filter-option>
        <div class="mt-3 mb-3">
          <InputText
            v-model="form.name"
            class="input"
            textFloat="Name"
            placeholder="Name"
            type="text"
            name="text"
            isRequired
            :isValidate="$v.form.name.$error"
            :v="$v.form.name"
          />
        </div>
        <div class="mt-3 mb-3">
          <InputText
            v-model="form.quantity"
            class="input"
            textFloat="Quantity"
            placeholder="0"
            type="number"
            name="number"
            isRequired
            :isValidate="$v.form.quantity.$error"
            :v="$v.form.quantity"
          />
        </div>
      </template>
    </SideBarFilter>
    <ModalAlertText :textModal="textModal" :arrModal="arrStatus" />
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import ModalAlertText from "@/components/modal/ModalAlertText.vue";
export default {
  name: "ServiceBranchZoneForm",
  components: { ModalAlertText },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    form: { type: Object, required: true },
  },
  watch: {
    visible(val) {
      if (val === true) {
        this.$refs.sidebarServiceBranchZone.show();
      } else {
        this.$refs.sidebarServiceBranchZone.hide();
        this.clearForm();
      }
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {},
      textModal: "",
      arrStatus: [],
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        quantity: { required },
      },
    };
  },
  methods: {
    async handleSave() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isLoading = true;
      let respone = null;

      if (this.form.id) {
        const payload = { ...this.form };

        delete payload.id;
        respone = await this.$services.event.updateEventZone(
          this.$route.params.id,
          this.form.id,
          payload
        );
      } else {
        respone = await this.$services.event.insertEventZone(
          this.$route.params.id,
          this.form
        );
      }
      this.openModalAlertText(respone.detail);
      this.isLoading = false;
      this.$emit("getZone");
      this.clearForm();
    },
    clearForm() {
      this.$v.$reset();
      this.$emit("clearForm");
    },
    openModalAlertText(text) {
      this.textModal = text;
      this.$bvModal.msgBoxOk(text, {
        size: "md",
        buttonSize: "md",
        contentClass: " tw-text-center tw-text-md tw-font-bold",
        headerClass: "p-2 border-bottom-0",
        footerClass: "p-2 border-top-0 tw-text-center",
        centered: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
