<template>
  <div>
    <SideBarFilter
      :filter="configFilter"
      ref="sidebarFilterCustomer"
      placeholder="Transaction"
      :hideStatusFilter="false"
      @searchAll="searchAll"
      @clearFilter="clearFilter"
      @clearForm="clearForm"
      handleSideBar
    >
      <template v-slot:filter-option>
        <div class="mt-3 mb-3">
          <InputSelect
            v-model="configFilter.statusId"
            :options="optionsStatus"
            title="สถานะ"
            class="mt-2"
            valueField="transactionStatusId"
            textField="name"
          />
        </div>
        <div class="mt-3 mb-3">
          <InputSelect
            v-model="configFilter.paymentTypeId"
            :options="optionsPayment"
            title="ช่องทางการชำระเงิน"
            class="mt-2"
            valueField="paymentTypeId"
            textField="name"
          />
        </div>
        <div class="d-flex">
          <div class="mb-3">
            <div class="font-weight-bold mb-2">วันที่สร้างเริ่มต้น</div>
            <div :class="['input-container']">
              <datetime
                v-model="configFilter.createdTime.startTime"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="createStartDate"
                :max-datetime="maxDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.createStartDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div class="mx-1"></div>
          <div class="mb-3">
            <div class="font-weight-bold mb-2">วันที่สร้างสิ้นสุด</div>
            <div :class="['input-container']">
              <datetime
                v-model="configFilter.createdTime.endTime"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="createEndDate"
                :disabled="!configFilter.createdTime.startTime"
                :max-datetime="maxDate"
                :min-datetime="minDateCreate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.createEndDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
            <p
              class="text-error"
              v-if="$v.configFilter.createdTime.endTime.$error"
            >
              กรุณาเลือก
            </p>
          </div>
        </div>
        <div class="d-flex">
          <div class="mb-3">
            <div class="font-weight-bold mb-2">วันที่ชำระเงินเริ่มต้น</div>
            <div :class="['input-container']">
              <datetime
                v-model="configFilter.paymentTime.startTime"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="paymentStart"
                :max-datetime="maxDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.paymentStart.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div class="mx-1"></div>
          <div class="mb-3">
            <div class="font-weight-bold mb-2">วันที่ชำระเงินสิ้นสุด</div>
            <div :class="['input-container']">
              <datetime
                v-model="configFilter.paymentTime.endTime"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="paymentEndDate"
                :disabled="!configFilter.paymentTime.startTime"
                :max-datetime="maxDate"
                :min-datetime="minDatePayment"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.paymentEndDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
            <p
              class="text-error"
              v-if="$v.configFilter.paymentTime.endTime.$error"
            >
              กรุณาเลือก
            </p>
          </div>
        </div>

        <div class="mb-3">
          <div class="font-weight-bold mb-2">รอบวันที่ + เวลาเริ่มต้น</div>
          <div :class="['input-container']">
            <datetime
              type="datetime"
              v-model="configFilter.bookingDate.startTime"
              :input-style="styleDatetime"
              format="dd/MM/yyyy HH:mm:ss"
              value-zone="Asia/Bangkok"
              placeholder="Please Select Date"
              ref="bookingDateStart"
              :phrases="{ ok: 'Continue', cancel: 'Exit' }"
              use24-hour
            >
            </datetime>
            <div
              class="icon-primary text-right"
              @click="$refs.bookingDateStart.isOpen = true"
            >
              <font-awesome-icon
                icon="calendar-alt"
                class="pointer g-text-gold"
                color="#B41BB4"
              />
            </div>
          </div>
        </div>
        <div class="mx-1"></div>
        <div class="mb-3">
          <div class="font-weight-bold mb-2">รอบวันที่ + เวลาสิ้นสุด</div>
          <div :class="['input-container']">
            <datetime
              type="datetime"
              v-model="configFilter.bookingDate.endTime"
              :input-style="styleDatetime"
              format="dd/MM/yyyy HH:mm:ss"
              value-zone="Asia/Bangkok"
              placeholder="Please Select Date"
              ref="bookingDateEndDate"
              :disabled="!configFilter.bookingDate.startTime"
              :min-datetime="minDateBooking"
              :phrases="{ ok: 'Continue', cancel: 'Exit' }"
              use24-hour
            >
            </datetime>
            <div
              class="icon-primary text-right"
              @click="$refs.bookingDateEndDate.isOpen = true"
            >
              <font-awesome-icon
                icon="calendar-alt"
                class="pointer g-text-gold"
                color="#B41BB4"
              />
            </div>
          </div>
          <p
            class="text-error"
            v-if="$v.configFilter.bookingDate.endTime.$error"
          >
            กรุณาเลือก
          </p>
        </div></template
      ></SideBarFilter
    >
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  name: "ServiceBranchCustomerFilter",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    configFilter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // state sidebar
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      maxDate: null,
      minDateBooking: null,
      minDatePayment: null,
      minDateCreate: null,

      optionsPayment: [],
      optionsStatus: [],
    };
  },
  validations() {
    return {
      configFilter: {
        createdTime: {
          endTime: {
            required: requiredIf(() => {
              return this.configFilter.createdTime.startTime;
            }),
          },
        },
        paymentTime: {
          endTime: {
            required: requiredIf(() => {
              return this.configFilter.paymentTime.startTime;
            }),
          },
        },
        bookingDate: {
          endTime: {
            required: requiredIf(() => {
              return this.configFilter.bookingDate.startTime;
            }),
          },
        },
      },
    };
  },
  watch: {
    visible(val) {
      if (val === true) {
        this.getStatusOption();
        this.getPaymentOption();
        this.$refs.sidebarFilterCustomer.show();
      } else {
        this.clearForm();
        this.$refs.sidebarFilterCustomer.hide();
      }
    },
    "configFilter.createdTime.startTime"(val) {
      if (val) {
        this.minDateCreate = new Date(val).toISOString();

        // clear endtime
        this.configFilter.createdTime.endTime = null;
      }
    },
    "configFilter.paymentTime.startTime"(val) {
      if (val) {
        this.minDatePayment = new Date(val).toISOString();

        // clear endtime
        this.configFilter.paymentTime.endTime = null;
      }
    },
    "configFilter.bookingDate.startTime"(val) {
      if (val) {
        this.minDateBooking = new Date(val).toISOString();

        // clear endtime
        this.configFilter.bookingDate.endTime = null;
      }
    },
  },
  mounted() {
    // condition date
    this.maxDate = new Date().toISOString();
  },
  methods: {
    async getPaymentOption() {
      const getData = await this.$services.master.getPaymentOption();

      if (getData.result == 1) {
        this.optionsPayment = [
          {
            name: "All",
            transactionStatusId: null,
          },
          ...getData.detail,
        ];
      }
    },
    async getStatusOption() {
      const getData = await this.$services.master.getStatusOption();

      if (getData.result == 1) {
        this.optionsStatus = [
          {
            name: "All",
            transactionStatusId: null,
          },
          ...getData.detail,
        ];
      }
    },
    searchAll(filter) {
      this.$v.configFilter.$touch();
      if (this.$v.configFilter.$error) {
        return;
      }
      this.$emit("searchAll", filter);
    },
    clearFilter() {
      this.$v.configFilter.$reset();
      this.$emit("clearFilter");
    },
    clearForm() {
      this.$emit("clearForm");
    },
  },
};
</script>

<style lang="scss" scoped></style>
