<template>
  <div class="text-center text-black my-2" v-if="loading">
    <b-spinner class="align-middle"></b-spinner>
    <strong class="ml-2">Loading... </strong>
  </div>
  <div v-else>
    <b-container class="bv-example-row bv-example-row-flex-cols">
      <b-row
        ><b-col>
          <div class="my-3">
            <InputText
              disabled
              v-model="form.event.eventName[indexNameLanguage].name"
              class="input"
              :textFloat="`Name`"
              placeholder="Name"
              type="text"
              name="name"
              isRequired
              :selectLang="language"
              :v="v.form.event.eventName.$each[indexNameLanguage].name"
              :isValidate="
                v.form.event.eventName.$each[indexNameLanguage].name.$error
              "
            />
          </div>
        </b-col>
        <b-col>
          <div class="my-3">
            <AutocompleteBranch
              placeholder="Branch"
              label="Branch"
              :optionsList="optionVenue"
              ref="autocompleteBranch"
              valueField="id"
              dataListID="id"
              showValue="provinceName"
              @handleInput="handleInput"
              :value="form.event.venueId"
              v-model="form.event.venueId"
              id="id"
              isRequired="true"
              :v="v.form.event.venueId"
              :isValidate="v.form.event.venueId.$error"
              absolute
            />
            <!-- <InputSelect
              v-model="form.event.venueId"
              :options="optionVenue"
              title="Branch"
              name="Branch"
              isRequired
              valueField="id"
              textField="name"
              @onDataChange="($event) => (form.event.venueId = $event)"
            >
              <template v-slot:option-first>
                <b-form-select-option :value="null" disabled
                  >-- Select Branch --
                </b-form-select-option>
              </template>
            </InputSelect> -->
          </div>
        </b-col></b-row
      >
      <b-row>
        <b-col>
          <div class="my-3">
            <div class="description">
              <div
                class="header tw-flex tw-justify-between tw-h-14 tw-items-center"
              >
                <div class="">Description</div>
                <div class="tw-mt-4">
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                      v-model="form.event.useDescriptionTemplate"
                      id="radio-slots"
                      :options="options"
                      :aria-describedby="ariaDescribedby"
                      name="radio-options-slots"
                    >
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
              <div class="tw-mt-2">
                <TextEditorsTiny
                  :value="
                    form.event.description[indexDescriptionLanguage].description
                  "
                  textFloat="Description"
                  :rows="4"
                  :name="'description_' + 1"
                  placeholder="Type something..."
                  @onDataChange="
                    (val) =>
                      (form.event.description[
                        indexDescriptionLanguage
                      ].description = val)
                  "
                  :selectLang="language"
                  :v="
                    v.form.event.description.$each[indexDescriptionLanguage]
                      .description
                  "
                  :isValidate="
                    v.form.event.description.$each[indexDescriptionLanguage]
                      .description.$error
                  "
                  isRequired
                  :disabled="form.event.useDescriptionTemplate"
                />
              </div>
            </div></div></b-col
      ></b-row>

      <b-row
        ><b-col
          ><div class="my-3">
            <InputTextArea
              v-model="
                form.event.shortDescription[indexShortDescriptionLanguage]
                  .shortDescription
              "
              textFloat="ShortDescription"
              type="text"
              class="mb-3 custom-input"
              placeholder=""
              rows="4"
              :selectLang="language"
              :v="
                v.form.event.shortDescription.$each[
                  indexShortDescriptionLanguage
                ].shortDescription
              "
              :isValidate="
                v.form.event.shortDescription.$each[
                  indexShortDescriptionLanguage
                ].shortDescription.$error
              "
              isRequired
              :disabled="form.event.useDescriptionTemplate"
            /></div></b-col
      ></b-row>
      <!-- <b-row
        ><b-col
          ><div class="my-3">
            <InputTextArea
              v-model="form.event.remark[indexRemarkLanguage].remark"
              textFloat="Remark"
              type="text"
              class="mb-3 custom-input"
              placeholder=""
              rows="4"
              :selectLang="language"
              isRequired
              :v="v.form.event.remark.$each[indexRemarkLanguage].remark"
              :isValidate="
                v.form.event.remark.$each[indexRemarkLanguage].remark.$error
              "
            /></div></b-col
      ></b-row> -->
      <b-row
        ><b-col>
          <div class="my-3">
            <div class="description"><div class="header">Condition</div></div>
          </div></b-col
        ></b-row
      >
      <b-row
        ><b-col>
          <InputText
            v-model="form.event.maximumDayBeforeReserve"
            class="w-100"
            textFloat="Maximum days booking (Day)"
            placeholder="0"
            type="number"
            name="number" /></b-col
        ><b-col>
          <InputText
            v-model="form.event.minimumDayBeforeReserve"
            class="w-100"
            textFloat="Minimum days required before booking (Day)"
            placeholder="0"
            type="number"
            name="number" /></b-col
      ></b-row>
      <b-row class="tw-h-20"
        ><b-col>
          <div>
            <div class="font-weight-bold mb-2">Start Date (DD/MM/YYYY)</div>
            <div :class="['input-container']">
              <datetime
                v-model="form.event.startTimeDisplay"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="createStartDate"
              >
                <!-- v-model="configFilter.createdTime.endTime"
                  :disabled="!configFilter.createdTime.startTime"
                  :max-datetime="maxDate"
                  :min-datetime="minDateCreate" -->
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.createStartDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div></div></b-col
        ><b-col>
          <div>
            <div class="font-weight-bold mb-2">End Date (DD/MM/YYYY)</div>
            <div :class="['input-container']">
              <datetime
                v-model="form.event.endTimeDisplay"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="createEndDate"
                :min-datetime="minDateCreate"
              >
                <!-- v-model="configFilter.createdTime.endTime"
                  :disabled="!configFilter.createdTime.startTime"
                  :max-datetime="maxDate"
                   -->
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.createEndDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div></div></b-col
      ></b-row>
      <b-row
        ><b-col>
          <div class="mb-4 tw-ml-1 tw-flex flex-row tw-align-middle">
            <p
              class="tw-text-base tw-mt-3 tw-mr-4"
              :class="[
                `${
                  form.event.isActive ? 'tw-text-green-500' : 'tw-text-red-500'
                }`,
              ]"
            >
              {{ form.event.isActive ? "Active" : "Inactive" }}
            </p>
            <b-form-checkbox
              @change="onChangeStatus(form.event)"
              v-model="form.event.isActive"
              class="tw-text-center tw-self-center tw-cursor-pointer"
              switch
              :value="true"
              :unchecked-value="false"
            /></div></b-col
      ></b-row>
    </b-container>
  </div>
</template>

<script>
import AutocompleteBranch from "./component/AutocompleteBranch.vue";
export default {
  name: "ServiceBranchDetailTab",
  components: { AutocompleteBranch },
  props: {
    form: {
      type: Object,
      required: true,
    },
    language: {
      type: Object,
      required: false,
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    v: {
      type: Object,
      required: true,
    },
    isCurrenTab: { type: Boolean, required: true },
  },
  data() {
    return {
      optionVenue: [],
      options: [
        { text: "Use Template", value: true },
        { text: "Custom", value: false },
      ],
      filterOptionVenue: {
        keyword: null,
        provinceId: 0,
        eventGroupId: +this.$route.params.eventGroupId,
        skip: null,
        take: null,
      },
      // state datetime
      styleDatetime: {
        width: "100%",
        border: "none",
      },
    };
  },
  watch: {},

  mounted() {
    this.getOptionVenue();
  },
  computed: {
    indexNameLanguage() {
      return this.form && this.language
        ? this.form.event.eventName.findIndex(
            (x) => x.languageId === this.language.languageId
          )
        : 0;
    },
    indexDescriptionLanguage() {
      return this.form && this.language
        ? this.form.event.description.findIndex(
            (x) => x.languageId === this.language.languageId
          )
        : 0;
    },
    indexShortDescriptionLanguage() {
      return this.form && this.language
        ? this.form.event.shortDescription.findIndex(
            (x) => x.languageId === this.language.languageId
          )
        : 0;
    },
    indexRemarkLanguage() {
      return this.form && this.language
        ? this.form.event.remark.findIndex(
            (x) => x.languageId === this.language.languageId
          )
        : 0;
    },
    minDateCreate() {
      if (this.form.event.startTimeDisplay !== "") {
        return new Date(this.form.event.startTimeDisplay).toISOString();
      }
      return null;
    },
  },
  methods: {
    async getOptionVenue() {
      const respone = await this.$services.servicebranch.getBranchList(
        this.filterOptionVenue
      );
      if (respone.result === 1) {
        this.optionVenue = respone.detail.data;
      }
    },
    async onChangeStatus(val) {
      const { id, isActive } = val;
      const respone = await this.$services.eventgroup.saveStatus(id, isActive);
      this.openModalAlertText(respone.message);
    },
    handleInput(val) {
      this.filterOptionVenue.skip = 1;
      this.filterOptionVenue.take = 5;
      this.filterOptionVenue.keyword = val;

      setTimeout(() => {
        this.getOptionVenue();
      }, 1000);
    },
    openModalAlertText(val) {
      this.$emit("openModalAlertText", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.description {
  .header {
    padding: 15px;
    background: var(--secondary-color);
    color: var(--font-color);
  }
}
</style>
