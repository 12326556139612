<template>
  <div class="container">
    <div v-if="refId === ''">
      <b-container class="bv-example-row bv-example-row-flex-cols">
        <b-row
          ><b-col>
            <div class="header-title">
              <h1 class="title">Customer</h1>
              <b-button class="btn-filter ml-2" @click="handleFilter">
                <span class="d-none d-md-block">
                  <font-awesome-icon icon="filter" class="pointer" />
                  <span class="btn-text">Filter</span>
                </span>
              </b-button>
            </div></b-col
          ></b-row
        >
        <b-row
          ><b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template #cell(bookingDate)="{ item }">
                <p class="m-0">
                  {{ $moment(item.bookingDate).format("DD/MM/YYYY") }}
                </p>
              </template>
              <template #cell(statusId)="{ item }">
                <p class="m-0" :style="`color:${item.color};`">
                  {{ item.statusName }}
                </p>
              </template>
              <template v-slot:cell(actions)="{ item }">
                <div class="d-flex justify-content-center">
                  <p
                    class="tw-m-0 tw-underline tw-cursor-pointer"
                    @click="handleClickDetail(item)"
                  >
                    Detail
                  </p>
                </div>
              </template>
            </b-table></b-col
          ></b-row
        >

        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :rows="rows"
          :filter="filter"
          @pagination="pagination"
        />
      </b-container>
    </div>
    <div v-else>
      <detail :refId="refId" @goCustomerDetail="goCustomerDetail" />
    </div>
    <FilterCustomer
      :visible="visible"
      :configFilter="configFilter"
      @searchAll="searchAll"
      @clearFilter="clearFilter"
      @clearForm="clearForm"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Detail from "./component/detail";
import FilterCustomer from "./component/filterCustomer";
export default {
  name: "ServiceBranchCustomerTab",
  components: { Pagination, Detail, FilterCustomer },
  data() {
    return {
      configFilter: {
        statusId: null,
        paymentTypeId: null,
        eventId: "",
        createdTime: {
          startTime: null,
          endTime: null,
        },
        paymentTime: {
          startTime: null,
          endTime: null,
        },
        bookingDate: {
          startTime: null,
          endTime: null,
        },
      },
      visible: false,
      refId: "",
      items: [],
      filter: {
        filter: {
          eventId: null,
        },
        keyword: "",
        skip: 1,
        take: 10,
        page: 1,
      },
      isBusy: false,
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      fields: [
        {
          key: "firstname",
          label: "Customer Name",
          class: "w-50px text-nowrap",
        },
        {
          key: "grandTotal",
          label: "Grand Total",
          class: "w-50px text-nowrap",
        },
        {
          key: "bookingDate",
          label: "Booking Datetime",
          class: "w-50px text-nowrap",
        },
        {
          key: "statusId",
          label: "Status",
          class: "w-50px text-nowrap",
        },
        { key: "actions", label: "Detail", class: "w-50px text-nowrap" },
      ],
    };
  },
  mounted() {
    this.getCustomer();
  },
  methods: {
    async getCustomer() {
      this.filter.filter.eventId = this.$route.params.id;

      const getData = await this.$services.transaction.getTransactionList(
        this.filter
      );

      if (getData.result == 1) {
        this.items = getData.detail.data;
        this.rows = getData.detail.count;
      }
    },
    handleFilter() {
      this.visible = true;
    },
    clearForm() {
      this.visible = false;
    },
    goCustomerDetail() {
      this.refId = "";
    },
    handleClickDetail(val) {
      this.refId = val.refId;
    },
    handleChangeTake(value) {
      this.filter.skip = 1;
      this.filter.take = value;
      this.getCustomer();
    },
    pagination(page) {
      this.filter.skip = page;
      this.getCustomer();
    },
    searchAll(filter) {
      let { statusId, paymentTypeId, createdTime, paymentTime, bookingDate } =
        this.configFilter;

      let { search } = filter;

      const eventId = this.$route.params.id;

      let finalFilter = {
        filter: null,
        keyword: search ? search : "",
        page: 1,
        skip: 1,
        take: 10,
      };

      // validate filter

      // add value to filter

      let newConfigFilter = {};

      statusId && (newConfigFilter.statusId = statusId);
      paymentTypeId && (newConfigFilter.paymentTypeId = paymentTypeId);
      eventId && (newConfigFilter.eventId = Number(eventId));

      if (createdTime.startTime && createdTime.endTime) {
        newConfigFilter.createdTime = {
          startTime: null,
          endTime: null,
        };

        newConfigFilter.createdTime.startTime = createdTime.startTime;
        newConfigFilter.createdTime.endTime = createdTime.endTime;
      }

      if (paymentTime.startTime && paymentTime.endTime) {
        newConfigFilter.paymentTime = {
          startTime: null,
          endTime: null,
        };

        newConfigFilter.paymentTime.startTime = paymentTime.startTime;
        newConfigFilter.paymentTime.endTime = paymentTime.endTime;
      }

      if (bookingDate.startTime && bookingDate.endTime) {
        newConfigFilter.bookingDate = {
          startTime: null,
          endTime: null,
        };

        newConfigFilter.bookingDate.startTime = bookingDate.startTime;
        newConfigFilter.bookingDate.endTime = bookingDate.endTime;
      }

      const isObjEmpty = Object.keys(newConfigFilter).length;

      if (isObjEmpty > 0) {
        finalFilter.filter = newConfigFilter;
      }

      this.filter = finalFilter;
      this.visible = false;
      this.getCustomer();
    },
    clearFilter() {
      this.visible = false;
      this.configFilter = {
        statusId: null,
        paymentTypeId: null,
        eventId: "",
        createdTime: {
          startTime: null,
          endTime: null,
        },
        paymentTime: {
          startTime: null,
          endTime: null,
        },
        bookingDate: {
          startTime: null,
          endTime: null,
        },
      };

      this.filter = {
        filter: {
          eventId: null,
        },
        keyword: "",
        skip: 1,
        take: 10,
        page: 1,
      };
      this.getCustomer();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: white;
  // padding: 15px;
  min-height: 60vh;
  .title {
    font-size: 18px;
  }
  .header-title {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
  }
}
.btn-filter {
  width: 100%;
  max-width: 120px;
}
</style>
